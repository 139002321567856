import React, {useContext} from "react";
import { SunOutlined, MoonOutlined } from "@ant-design/icons";
import { ThemeContext } from "../../context/ThemeContext";
import '../../globalStyle.css'

const ThemeToggleBtn = () => {
    const { themeMode, toggleTheme } = useContext(ThemeContext);

    return (
        <div
            style={{
                position: 'fixed',
                top: '15px',
                right: '15px',
                zIndex: 1000,
                cursor: 'pointer',
            }}
            onClick={toggleTheme}
        >
           {themeMode === 'light' ? (
                <SunOutlined style={{ fontSize: '24px' }} />
            ) : (
                <MoonOutlined style={{ fontSize: '24px' }} />
            )}
        </div>
    )
}

export default ThemeToggleBtn;