import { useSelector } from 'react-redux';
import { FloatButton, Dropdown, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export const TMSUploader = () => {
  const { queue, progress, isUploading } = useSelector(state => state.tmsUploader);

  if (!isUploading) return null;

  const items = queue.map(file => ({
    key: file.name,
    label: (
      <div key={file.name} style={{ padding: '5px 10px', width: '200px' }}>
        <div>{file.name}</div>
        <Progress percent={progress} size='small' />
      </div>
    ),
  }));

  return (
    <Dropdown menu={{ items }} trigger={['click']} placement='bottomRight'>
      <FloatButton
        icon={<UploadOutlined />}
        type='primary'
        style={{ position: 'fixed', top: 72, right: 20, zIndex: 1000 }}
      />
    </Dropdown>
  );
};
