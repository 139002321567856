import axios from 'axios';
import { LOCAL_STORAGE_KEYS, TILE_SERVICE_API_BASE_URL } from '../utils/consts';
import { getUserData } from '../utils/lib';

export const axiosTms = axios.create({
  baseURL: TILE_SERVICE_API_BASE_URL,
  withCredentials: true,
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = newToken => {
  refreshSubscribers.forEach(callback => callback(newToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = callback => {
  refreshSubscribers.push(callback);
};

axiosTms.interceptors.request.use(
  config => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TMS_ACCESS_TOKEN);
    const { userId } = getUserData();
    config.headers.userId = userId;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosTms.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry &&
      !originalRequest.url.includes('/api/auth/login')
    ) {
      if (isRefreshing) {
        return new Promise(resolve => {
          addRefreshSubscriber(newToken => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(axiosTms(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axiosTms.post(
          'http://localhost:5500/api/auth/refresh'
        );

        const newToken = response.data.accessToken;
        localStorage.setItem(LOCAL_STORAGE_KEYS.TMS_ACCESS_TOKEN, newToken);
        isRefreshing = false;
        onRefreshed(newToken);

        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axiosTms(originalRequest);
      } catch (refreshError) {
        isRefreshing = false;
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TMS_ACCESS_TOKEN);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);
