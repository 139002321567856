import React, { useState, useEffect, useRef } from 'react';

function ConsumersStats({ consumer, updateInterval = 1000 }) {
  const [rtt, setRtt] = useState(0);
  const [fps, setFps] = useState(0);

  // предыдущие значения:
  const prevFrames = useRef(0);
  const prevTime = useRef(Date.now());

  useEffect(() => {
    if (!consumer) return;
    if (consumer.kind !== 'video') return;

    const intervalId = setInterval(async () => {
      try {
        const stats = await consumer.getStats();

        let newRtt = 0;
        let framesDecoded = 0;
        for (const report of stats) {
          const stat = report[1];
          if (stat.type === 'candidate-pair' && stat.currentRoundTripTime) {
            newRtt = stat.currentRoundTripTime * 1000; // в мс
          } else if ((stat.type === 'inbound-rtp' || stat.type === 'outbound-rtp') && stat.roundTripTime) {
            newRtt = stat.roundTripTime * 1000; // в мс
          }

          if (stat.type === 'inbound-rtp' && stat.kind === 'video') {
            framesDecoded = stat.framesDecoded || 0;
          }
        }

        // Вычисление FPS
        const now = Date.now();
        const timeDiffSec = (now - prevTime.current) / 1000;
        let newFps = 0;
        if (timeDiffSec > 0) {
          newFps = (framesDecoded - prevFrames.current) / timeDiffSec;
        }

        setRtt(Math.round(newRtt));
        setFps(Math.round(newFps * 10) / 10);
        prevFrames.current = framesDecoded;
        prevTime.current = now;
      } catch (err) {
        console.error('Ошибка getStats для consumer:', err);
      }
    }, updateInterval);

    return () => clearInterval(intervalId);
  }, [consumer, updateInterval]);

  return (
    <div
      style={{
        position: 'absolute',
        top: '7px',
        left: '5px',
        color: 'lightgreen',
        padding: '2px 4px',
        fontSize: 12,
        borderRadius: 4,
        backgroundColor: 'rgba(0,0,0,0.6)',
      }}
    >
      <div>Ping: {rtt} ms</div>
      <div>FPS: {fps}</div>
    </div>
  );
}

export default React.memo(ConsumersStats);
