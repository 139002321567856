import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge, Menu, Dropdown } from 'antd';
import { isMobile } from 'react-device-detect';
import Drawer from '../DrawerProfile/DrawerProfile';
import './index.css';
import styles from './index.module.css';
import { MenuOutlined,
  LogoutOutlined,
  AppstoreOutlined,
  SnippetsOutlined,
  ClockCircleOutlined,
  PlayCircleOutlined,
  TeamOutlined,
  SolutionOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
  BookOutlined,
  AreaChartOutlined,
  MessageOutlined,
  BorderOuterOutlined,
  DesktopOutlined,
  PhoneOutlined
  } from '@ant-design/icons';
import { AuthContext } from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';

const NavBar = () => {
  const location = useLocation();
  const auth = useContext(AuthContext);
  const { isAdmin, userState, status } = useSelector((state) => state.userInfo);
  const showUnreadIndicator = useSelector((state) => state.ui.showUnreadIndicator);
  const translationGroup = useSelector((state) => state.userInfo.translationGroup);
  const { themeMode } = useContext(ThemeContext);

  const [isDot, setIsDot] = useState(false);

  useEffect(() => {
    if (typeof translationGroup === 'string') {
      setIsDot(true);
    } else {
      setIsDot(false);
    }
  }, [translationGroup]);

  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
    window.location.reload();
  };

  const getSelectedKey = (pathname) => {
    if (pathname.startsWith('/directory/forwarder')) return 'forwarder';
    if (pathname.startsWith('/messages')) return 'messages';
    if (pathname.startsWith('/app')) return 'app';
    if (pathname.startsWith('/geo')) return 'geo_data';
    if (pathname.startsWith('/map')) return 'maps';
    if (pathname.startsWith('/registered')) return 'registration_requests';
    if (pathname.startsWith('/translation')) return 'translation';
    if (pathname.startsWith('/users_requests')) return 'new_user_requests';
    if (pathname.startsWith('/users')) return 'users';
    if (pathname.startsWith('/group_translations')) return 'group_translations';
    if (pathname.startsWith('/device_offline')) return 'device_offline';
    if (pathname.startsWith('/statistics')) return 'statistics';
    if (pathname.startsWith('/call_page')) return 'call_page';
    if (pathname.startsWith('/localmachine_registration')) return 'localmachine_registration';
    return '';
  };

  const items = [
    {
      key: 'forwarder',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/directory/forwarder' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <SnippetsOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/directory/forwarder" className={styles.NavHover}>
                Список устройств
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'messages',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/messages' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <MessageOutlined className={styles.NavHover}/>
              <Badge dot={showUnreadIndicator}>
                <NavLink to="/messages" className={styles.NavHover}>
                  Сообщения
                </NavLink>
              </Badge>
          </span>
        </div>
      ),
    },
    {
      key: 'app',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/app' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <AppstoreOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/app" className={styles.NavHover}>
                Приложения
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'geo_data',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/geo' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <BorderOuterOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/geo" className={styles.NavHover}>
                Геоданные
              </NavLink>
          </span>
        </div>
      ),
    },
    // {
    //   key: 'maps',
    //   label:(
    //     <span className={styles.NavContainer}>
    //       <ReadOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
    //         <NavLink to="/map" className={styles.NavHover}>Карты</NavLink>
    //     </span>
    //   ),
    // },
    {
      key: 'registration_requests',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/registered' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <ClockCircleOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/registered" className={styles.NavHover}>
                Запросы
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'translation',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/translation' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <PlayCircleOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/translation" className={styles.NavHover}>
                Трансляция
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'users',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/users' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <TeamOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/users" className={styles.NavHover}>
                Пользователи
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'new_user_requests',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/users_requests' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <SolutionOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/users_requests" className={styles.NavHover}>
                Добавление пользователей
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'group_translations',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/group_translations' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <UsergroupAddOutlined className={styles.NavHover}/>
              <Badge dot={typeof translationGroup === 'string'}>
                <NavLink to="/group_translations" className={styles.NavHover}>
                    Групповые трансляции
                  </NavLink>
                </Badge>
          </span>
        </div>
      ),
    },
    {
      key: 'device_offline',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/device_offline' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <BookOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/device_offline" className={styles.NavHover}>
                Оффлайн устройства
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'statistics',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/statistics' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <AreaChartOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/statistics" className={styles.NavHover}>
                Статистика
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'call_page',
      label:(
        <div className={`${styles.NavHover} ${location.pathname === '/call_page' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <PhoneOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/call_page" className={styles.NavHover}>
                Звонки
              </NavLink>
          </span>
        </div>
      ),
    },
    {
      key: 'localmachine_registration',
      label:(
        <div className={`${styles.NavHover} ${window.location.pathname === '/localmachine_registration' ? styles.activeNavItem : ''}`}>
          <span className={styles.NavContainer}>
            <DesktopOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
              <NavLink to="/localmachine_registration" className={styles.NavHover}>
                Локальные сервера
              </NavLink>
          </span>
        </div>
      ),
    },
  ].filter((el) => {
    if (isAdmin) {
      return true;
    } else {
      if (el.key === '1_exit') {
        return true;
      } else {
        if (userState.responseAccesses) {
          return userState.responseAccesses[el.key];
        }
      }
    }
  });

  if (isMobile) {
    items.push({
      key: '1_exit',
      icon: <LogoutOutlined />,
      label: (
        <a href="/" onClick={logoutHandler}>
          {' '}
          Выйти{' '}
        </a>
      ),
    });
  }

  return isMobile ? (
<Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
    <div style={{ position: 'absolute', right: '20px' }}>
      <a onClick={(e) => e.preventDefault()} style={{ color: themeMode === 'dark' ? '#fff' : '#000' }}>
        <MenuOutlined />
      </a>
    </div>
  </Dropdown>
) : (
  <>
  <div className={styles.menuWrapper}>
    <Menu
      mode="horizontal"
      theme="dark"
      items={status === 'resolved' ? items : []}
      inlineIndent={5}
      className={styles.menuNav}
    />
  </div>

      {status === 'resolved' && <Drawer />}
    </>
  );
};

export default NavBar;