import { configureStore } from '@reduxjs/toolkit';
import getInfoReducer from './userSlice';
import counterSlice from './counterSlice';
import uiReducer from './uiSlice';
import chatReducer from './chatSlice';
import unreadMessagesReducer from './unreadMessagesSlice';
import password2Reducer from './password2Slice';
import mapReducer from './mapSlice';
import loaderReducer from './loaderSlice';
import tmsUploaderReducer from './tmsUploaderSlice';
import tmsReducer from './tmsSlice';

export default configureStore({
  reducer: {
    userInfo: getInfoReducer,
    counter: counterSlice,
    ui: uiReducer,
    chat: chatReducer,
    unreadMessages: unreadMessagesReducer,
    password2: password2Reducer,
    map: mapReducer,
    loader: loaderReducer,
    tmsUploader: tmsUploaderReducer,
    tms: tmsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'uploads/addUpload',
          'uploads/reupload',
          'uploads/uploadFileInChunks',
          'uploads/uploadFileInChunks/rejected',
          'uploads/uploadChunk',
          'uploads/uploadChunk/rejected',
          'tmsUploader/uploadTMSFile',
          'tmsUploader/uploadTMSFile/rejected',
        ],
        ignoredPaths: [
          'uploads.uploads',
          'payload.path',
          'payload.file',
          'payload',
          'tmsUploader.queue',
        ],
      },
    }),
  // .concat(refreshTokensMiddleware),
});
