import React from 'react';
import { Table, Form, Select, FloatButton } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { useTMSMapTable } from './TMSMapTable.hooks';

export const TMSMapTable = () => {
  const {
    form,
    onFinish,
    loading,
    columns,
    dataMbtiles,
    selectedType,
    handleTypeChange,
    mapTypesWithoutHgt,
    paginationProps,
  } = useTMSMapTable();

  return (
    <>
      <Select
        value={selectedType}
        placeholder='Выберите тип карт'
        options={mapTypesWithoutHgt}
        onChange={handleTypeChange}
        className='custom-select'
        style={{
          width: '250px',
          marginBottom: '2%',
        }}
      />
      <Form form={form} onFinish={onFinish}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataMbtiles}
          pagination={paginationProps}
          scroll={isMobile ? null : { x: 2500 }}
          bordered
        />
      </Form>
      {selectedType ? (
        <FloatButton
          icon={<SyncOutlined />}
          tooltip={`Очистить фильтр`}
          onClick={() => handleTypeChange(null)}
        />
      ) : null}
    </>
  );
};
