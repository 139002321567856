import React from 'react';
import { Form, Modal, Select, Input, message, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import './index.css';
import { GEO_OPTION_VALUES, MAP_FILENAME_PATTERN, MAP_TYPES } from '../../utils/consts';
import { useGeoDataModal } from './GeoDataModal.hooks';
import { addToQueue, uploadTMSFile } from '../../store/tmsUploaderSlice';
import { useDispatch, useSelector } from 'react-redux';

export function GeoDataModal({
  actual,
  isGeoModalOpen,
  setIsGeoModalOpen,
  fetchGeoData,
}) {
  const {
    title,
    form,
    props,
    onCreate,
    selectedType,
    handleChange,
    fileList,
    setFileList,
    currentMapTypes,
  } = useGeoDataModal({
    actual,
  });
  const dispatch = useDispatch();
  const { isUploading } = useSelector(state => state.tmsUploader);

  return (
    <>
      <Modal
        title={title}
        open={isGeoModalOpen}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              if (fileList.length === 0) {
                message.error('Необходимо выбрать файл!');
                return; 
              }
              
              const file = fileList[0];
              const fileExtension = `.${file.name.split('.').pop()}`;
              const expectedExtension = selectedType === 'hgt' ? '.hgt' : '.mbtiles';
      
              // if (fileExtension !== expectedExtension) {
              //   message.error(
              //     `Расширение файла не совпадает с ${expectedExtension}!`
              //   );
              //   return;
              // }

              form.resetFields();
              if (actual === GEO_OPTION_VALUES.MAPS) {
                onCreate(values);
              } else if (actual === GEO_OPTION_VALUES.TMS) {
                try {
                  if (isUploading) {
                    notification.warning({
                      message: 'Выполняется загрузка',
                      description: 'Одновременно может загружаться только один файл.',
                    });
                    return;
                  }

                  dispatch(addToQueue({ file, name: values.filename, type: selectedType }));       
                  dispatch(uploadTMSFile());
                } catch (error) {
                  console.log(error);
                }
              }
              setIsGeoModalOpen(false);
              setFileList([]);
              fetchGeoData(actual);
            })
            .catch((info) => {
              console.log("Ошибка валидации:", info);
            });
            
        }}
        destroyOnClose={true}
        onCancel={() =>{
          setIsGeoModalOpen(false);
          form.resetFields(); 
          setFileList([]);
        } } cancelText="Отмена"
      >
        <Form form={form} layout='vertical'>
          {actual === GEO_OPTION_VALUES.MAPS || actual === GEO_OPTION_VALUES.TMS ? (
            <>
              <Form.Item
                name='filename'
                label='Имя файла'
                rules={[
                  {
                    required: true,
                    message: 'Это поле не может быть пустым!',
                  },
                  {
                    pattern: MAP_FILENAME_PATTERN,
                    message: 'Имя файла может содержать только латинские буквы, цифры, дефис, нижнее подчеркивание и точку!',
                  },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                name='type'
                label='Тип файла'
                style={{
                  position: 'relative',
                  width: '250px',
                  marginBottom: '3%',
                }}
                rules={[
                  {
                    required: true,
                    message: 'Это поле не может быть пустым!',
                  },
                ]}>
                <Select
                  placeholder='Выберите тип данных'
                  options={currentMapTypes}
                  value={selectedType}
                  onChange={handleChange}
                  style={{
                    width: '250px',
                  }}
                />
              </Form.Item>
            </>
          ) : null}
          <Form.Item valuePropName='fileList'
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (fileList.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Необходимо выбрать файл для загрузки!'));
                },
              },
            ]}
          >
            <Upload.Dragger {...props}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Перетащите сюда файлы, которые хотите загрузить
              </p>
              <p className='ant-upload-hint'>
                Поддерживается одиночная или массовая загрузка файлов.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
