import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Space, message } from 'antd';
import getFiltersInColumn from '../../utils/filteredColumns';
import { DeleteOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { MAP_TYPES, SOCKET_EVENTS } from '../../utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { on, removeAllListeners } from '../../socket';
import { axiosTms } from '../../api';
import {
  deleteTmsMapById,
  downloadTmsMapByFilename,
  getAllTmsMaps,
} from '../../store/tmsSlice';
import { clearUploadComplete } from '../../store/tmsUploaderSlice';

const mapTypesWithoutHgt = MAP_TYPES.filter(item => item.value !== 'hgt');

export const useTMSMapTable = () => {
  const [form] = Form.useForm();
  const [dataMbtiles, setDataMbtiles] = useState();
  const [nameBtn, setNameBtn] = useState(null);
  const [clickRowId, setClickRowId] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDataMbtiles, setTotalDataMbtiles] = useState(10);
  const [selectedType, setSelectedType] = useState(null);

  const dispatch = useDispatch();
  const { items: tmsItems, loading } = useSelector(state => state.tms);
  const { uploadComplete } = useSelector(state => state.tmsUploader);

  const handleCopyUrl = async text => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Ссылка скопирована');
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const columns = isMobile
    ? [
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
          // width: 150,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              dataMbtiles,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'id'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.id) {
              return record.id;
            } else {
              return record.id?.includes(value);
            }
          },
          sorter: (a, b) => a.name?.localeCompare(b.name),
          render: (text, record) => {
            return (
              <>
                {clickRowId === record.id && 'device_id' === nameBtn ? (
                  <>
                    <Form.Item name={'name'}>
                      <Input
                        className='tableInput'
                        ref={input => input && input.focus()}
                        onBlur={() => {
                          setClickRowId();
                          setNameBtn();
                        }}
                        value={text}
                        style={{ textAlign: 'center' }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: '25px',
                      }}
                      onClick={() => {
                        setClickRowId(record.id);
                        setNameBtn('device_id');
                      }}>
                      {text}
                    </div>
                  </>
                )}
              </>
            );
          },
        },
        {
          title: 'Действия',
          dataIndex: 'action',
          key: 'action',
          render: (text, record) => {
            return (
              <div className='ChartPage__fields'>
                <ol style={{ paddingLeft: 0 }}>
                  <Space direction='vertical' size='middle'>
                    <li style={{ listStyleType: 'none' }}>
                      <Button
                        type='primary'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          deleteById(record.id, record.path);
                        }}
                        style={{ width: '100%', position: 'relative' }}
                        danger>
                        Удалить поле
                      </Button>
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <Button
                        type='default'
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          downloadByFilename(record.name);
                        }}
                        style={{ width: '100%', position: 'relative' }}>
                        Скачать
                      </Button>
                    </li>
                  </Space>
                </ol>
              </div>
            );
          },
        },
      ]
    : [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
          width: 150,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              dataMbtiles,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'id'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.id) {
              return record.id;
            } else {
              return record.id?.includes(value);
            }
          },
          sorter: (a, b) => a.id - b.id,
          render: (text, record) => {
            return record.id;
          },
        },
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
          width: 100,
          editeble: true,
          render: (text, record) => {
            return (
              <span style={{ textTransform: 'capitalize' }}>{record.type}</span>
            );
          },
        },
        {
          title: 'Имя файла',
          dataIndex: 'name',
          key: 'name',
          width: 150,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              dataMbtiles,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'name'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.name) {
              return !record.name;
            } else {
              return record.name?.includes(value);
            }
          },
          sorter: (a, b) => a.name?.localeCompare(b.name),
          render: (text, record) => {
            return (
              <>
                {clickRowId === record.id && 'device_id' === nameBtn ? (
                  <>
                    <Form.Item name={'name'}>
                      <Input
                        className='tableInput'
                        ref={input => input && input.focus()}
                        onBlur={() => {
                          setClickRowId();
                          setNameBtn();
                        }}
                        value={text}
                        style={{ textAlign: 'center' }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: '25px',
                      }}
                      onClick={() => {
                        setClickRowId(record.id);
                        setNameBtn('device_id');
                      }}>
                      {text}
                    </div>
                  </>
                )}
              </>
            );
          },
        },
        {
          title: 'Ссылка',
          dataIndex: 'url',
          key: 'url',
          width: 150,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              dataMbtiles,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'url'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.name) {
              return !record.name;
            } else {
              return record.name?.includes(value);
            }
          },
          sorter: (a, b) => a.name?.localeCompare(b.name),
          render: (text, record) => {
            return (
              <>
                {clickRowId === record.id && 'device_id' === nameBtn ? (
                  <>
                    <Form.Item name={'name'}>
                      <Input
                        className='tableInput'
                        ref={input => input && input.focus()}
                        onBlur={() => {
                          setClickRowId();
                          setNameBtn();
                        }}
                        value={text}
                        style={{ textAlign: 'center' }}
                      />
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: '25px',
                      }}
                      onClick={() => {
                        setClickRowId(record.id);
                        setNameBtn('device_id');
                      }}>
                      <Button
                        type='link'
                        onClick={() => handleCopyUrl(text)}
                        style={{ fontSize: 'inherit' }}>
                        {text}
                      </Button>
                    </div>
                  </>
                )}
              </>
            );
          },
        },
        {
          title: 'Путь к файлу',
          dataIndex: 'path',
          key: 'path',
          width: 150,
          editeble: true,
          filterDropDown: ({ setSelectedKeys, selectedKeys, confirm }) =>
            getFiltersInColumn(
              dataMbtiles,
              setSelectedKeys,
              selectedKeys,
              confirm,
              'path'
            ),
          onFilter: (value, record) => {
            if (value === 'нет данных' && !record.id) {
              return !record.path;
            } else {
              return record.path?.includes(value);
            }
          },
          sorter: (a, b) => a.path?.localeCompare(b.path),
          render: (text, record) => {
            return record.path;
          },
        },
        {
          title: 'Действия',
          dataIndex: 'action',
          key: 'action',
          width: 150,
          render: (text, record) => {
            return (
              <div className='ChartPage__fields'>
                <Space>
                  <Button
                    type='primary'
                    icon={<DownloadOutlined />}
                    loading={record.mergeStatus === 'loading'}
                    onClick={() => {
                      downloadByFilename(record.name);
                    }}>
                    Скачать карту
                  </Button>
                  <Button
                    danger
                    type='link'
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      deleteById(record.id, record.path);
                    }}>
                    Удалить поле
                  </Button>
                </Space>
              </div>
            );
          },
        },
      ];

  useEffect(() => {
    on(SOCKET_EVENTS.MAP_MERGE_SUCCESS, () => {       
      getAll();
    });

    return () => {
      removeAllListeners(SOCKET_EVENTS.MAP_MERGE_SUCCESS);
    };
  }, []);

  useEffect(() => {
    setPage(1);
    getAll({ type: selectedType, offset: 0 });
  }, [selectedType]);

  useEffect(() => {
    if (tmsItems) {
      setTotalDataMbtiles(tmsItems.count);
      setDataMbtiles(tmsItems.rows?.map((el, i) => ({ ...el, key: i + 1 })));
    }
  }, [tmsItems]);

  useEffect(() => {
    if (uploadComplete) {
      getAll();
      dispatch(clearUploadComplete());
    }
  }, [uploadComplete, page, pageSize]);

  const downloadByFilename = async filename => {
    dispatch(downloadTmsMapByFilename(filename));
  };

  const deleteById = id => {
    dispatch(deleteTmsMapById(id)).then(() => getAll());
  };

  const getAll = ({ offset = (page - 1) * pageSize, limit, type } = {}) => {
    dispatch(getAllTmsMaps({ offset, limit, type }));
  };

  const handleTypeChange = value => {
    setSelectedType(value);
  };

  const onFinish = async values => {
    try {
      getAll();
      setClickRowId();
      setNameBtn();
      form.resetFields();
    } catch (error) {
      console.error('[onFinish]:', error, error.message);
    }

    try {
      await axiosTms.patch(`/api/maps/${clickRowId}`, {
        id: clickRowId,
        name: values['name'],
        path: values['path'],
      });

      getAll();
      setClickRowId();
      setNameBtn();
      form.resetFields();
    } catch (error) {
      console.error('[onFinish]:', error, error.message);
    }
  };

  const paginationProps = {
    position: ['bottomCenter'],
    showSizeChanger: false,
    current: page,
    pageSize: pageSize,
    total: totalDataMbtiles,
    onChange: async (newPage, newPageSize) => {
      setPage(newPage);
      setPageSize(newPageSize);
      getAll({ offset: (newPage - 1) * newPageSize, type: selectedType });
    },
  };

  return {
    form,
    onFinish,
    loading,
    columns,
    dataMbtiles,
    selectedType,
    handleTypeChange,
    mapTypesWithoutHgt,
    paginationProps,
  };
};
