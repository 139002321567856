import React, { useContext } from 'react'; 
import { Link } from 'react-router-dom';
import { Header } from 'antd/lib/layout/layout';
import NavBar from '../navBar/NavBar';
import logo from '../../images/storm_icon-icons.com_67503.png';
import { ThemeContext } from '../../context/ThemeContext'; 
import './index.css';

const DefaultHeader = () => {
  const { themeMode } = useContext(ThemeContext); 

  return (
    <Header
      className="ant-layout-header"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 100,
        width: '100%',
        backgroundColor: themeMode === 'dark' ? '#2e2e2e' : '#001529', 
        color: themeMode === 'dark' ? '#ffffff' : '#ffffff', 
        transition: 'background-color 0.3s ease, color 0.3s ease', 
      }}
    >
      <Link className="logo" to={'/'}>
        <img alt="logo" width="40%" src={logo} />
      </Link>
      <NavBar />
    </Header>
  );
};

export default DefaultHeader;