import { useState, useCallback } from 'react';
import { fetchRefreshTokens } from '../http/refresh';
import { NEW_TOKEN_AWAITING_KEY } from '../utils/consts';

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const userData = localStorage.getItem('userData');
  // let token = null;
  // if (userData) {
  //   token = JSON.parse(userData).token;
  //   console.log('token >>>', token);
  // }

  // let headers = { Authorization: `Bearer ${token}` };

  const request = useCallback(
    async (
      url,
      method = 'GET',
      body = null,
      adminInfo = false
      // headers = { Authorization: `Bearer ${token}` }
    ) => {
      const userData = localStorage.getItem('userData');
      let token = null;

      if (userData) {
        token = JSON.parse(userData).token;
      }

      let headers = { Authorization: token, admin_info: adminInfo };
      setLoading(true);
      let data = null;

      try {
        if (body) {
          body = JSON.stringify(body);
          headers['Content-Type'] = 'application/json';
        }

        const response = await fetch(url, {
          method,
          body,
          headers,
        });

        data = response.status === 204 ? [] : await response.json();

        if (response.status === 401 && userData) {
          try {
			if (localStorage.getItem(NEW_TOKEN_AWAITING_KEY) === 'true') {
				localStorage.setItem(NEW_TOKEN_AWAITING_KEY, 'false')
				return;
			}
			localStorage.setItem(NEW_TOKEN_AWAITING_KEY, 'true');
            const { response: refreshResponse, data: refreshData } = await fetchRefreshTokens().finally(() => 
				localStorage.setItem(NEW_TOKEN_AWAITING_KEY, 'false')
			);
            if (refreshResponse.status === 409 || refreshResponse.status === 403) {
              localStorage.clear();
              window.location.reload();
            }

            /* --- повторный запрос при смене токена --- */
            let newResponse = null;

            if (method !== 'GET') {
              newResponse = await fetch(url, {
                method,
                headers: {
                  Authorization: `Bearer ${refreshData.tokens.access.token}`,
                  'Content-Type': 'application/json',
                  admin_info: adminInfo,
                },
                body,
              });
            } else {
              newResponse = await fetch(url, {
                method,
                headers: {
                  Authorization: `Bearer ${refreshData.tokens.access.token}`,
                  admin_info: adminInfo,
                },
              });
            }

            data = newResponse.status === 204 ? [] : await newResponse.json();
            /* --- END повторный запрос при смене токена --- */
          } catch (error) {
            console.log('ERROR !!! >>>', error);
          }
        } else if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }


        // if (!response.ok && data.code !== 403) {
        //   throw new Error(data.message || 'Что-то пошло не так');
        // }

        setLoading(false);
        return data;
      } catch (e) {
        setError(e.message);
        setLoading(false);
        throw e;
      }
    },
    []
  );

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError, setLoading };
};
