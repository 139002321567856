import React, { createContext, useState, useMemo, useEffect } from "react";
import { theme } from "antd";

const { darkAlgorithm, defaultAlgorithm } = theme;

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState(() => {
    return localStorage.getItem('themeMode') || 'light';
  });

  const toggleTheme = () => {
    const newTheme = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newTheme);
    localStorage.setItem('themeMode', newTheme);
  };

  const themeConfig = useMemo (() => ({
    algorithm: themeMode === 'dark' ? darkAlgorithm : defaultAlgorithm,
  }), [themeMode]);

  useEffect(() => {
    if (themeMode === 'dark') {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
    } else {
      document.body.classList.add('light-mode');
      document.body.classList.remove('dark-mode');
    }
  }, [themeMode]);

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme, themeConfig }}>
      {children}
    </ThemeContext.Provider>
  );
};