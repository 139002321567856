import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import UserStore from './store/UserStore';
import { Provider } from 'react-redux';
import store from './store';
import './globalStyle.css';

import { ConfigProvider } from 'antd';
import { ThemeContext, ThemeProvider } from './context/ThemeContext';

import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';

export const Context = createContext(null);

ReactDOM.createRoot(document.getElementById('root')).render(
<ErrorBoundary FallbackComponent={ErrorFallback} onError={(e) => { console.log(e, 'error') }}>
  <Provider store={store}>
    <ThemeProvider>
      <Context.Provider
        value={{
          user: new UserStore(),
        }}
      >
        <ThemeContext.Consumer>
          {({ themeConfig }) => (
            <ConfigProvider theme={themeConfig}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ConfigProvider>
          )}
        </ThemeContext.Consumer>
      </Context.Provider>
    </ThemeProvider>
  </Provider>
</ErrorBoundary>
);