import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { axiosTms } from '../../api';

export const TMSLoginForm = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axiosTms.post('/api/auth/login', values);
      if (onSuccess) onSuccess(response.data.accessToken);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error('Ошибка. Пожалуйста, попробуйте снова.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      name="login"
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ email: '', password: '' }}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Пожалуйста, введите почту' },
          { type: 'email', message: 'Неверный адрес почты' },
        ]}
      >
        <Input placeholder="Почта" prefix={<UserOutlined className="site-form-item-icon" />} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'Пожалуйста, введите пароль' },
          { min: 6, message: 'Не менее 6 символов' },
        ]}
      >
        <Input.Password placeholder="Пароль" prefix={<LockOutlined className="site-form-item-icon" />} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Войти
        </Button>
      </Form.Item>
    </Form>
  );
};
