import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosTms } from '../api';
import { getAllTmsMaps } from './tmsSlice';
import { message } from 'antd';

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size

export const uploadTMSFile = createAsyncThunk(
  'tmsUploader/uploadTMSFile',
  async (_, { dispatch, getState }) => {
    const state = getState().tmsUploader;

    const fileObj = state.queue[0];
    const { file, name, type } = fileObj;
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let uploadedChunks = 0;

    dispatch(startUpload(fileObj));

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const chunk = file.slice(
        chunkIndex * CHUNK_SIZE,
        (chunkIndex + 1) * CHUNK_SIZE
      );
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('originalFilename', file.name);
      formData.append('name', name);
      formData.append('type', type);
      formData.append('chunkIndex', chunkIndex);
      formData.append('totalChunks', totalChunks);

      try {
        await axiosTms.post(
          '/api/maps/upload-chunk',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        uploadedChunks++;
        dispatch(
          updateProgress(Math.round((uploadedChunks / totalChunks) * 100))
        );
      } catch (error) {
        console.error('[uploadFile]:', error);

        let errorMessage = 'Ошибка при загрузке файла';
        if (error.response?.data?.message) errorMessage = error.response.data.message;
        message.error(errorMessage);

        dispatch(uploadFailed());
        return;
      }
    }

    dispatch(uploadCompleted());
    dispatch(getAllTmsMaps())
  }
);

const tmsUploaderSlice = createSlice({
  name: 'tmsUploader',
  initialState: {
    queue: [],
    progress: 0,
    isUploading: false,
    uploadComplete: false,
  },
  reducers: {
    addToQueue: (state, action) => {
      state.queue = [action.payload];
      state.isUploading = true;
      state.uploadComplete = false;
    },
    startUpload: state => {
      state.isUploading = true;
      state.progress = 0;
      state.uploadComplete = false;
    },
    updateProgress: (state, action) => {
      state.progress = action.payload;
    },
    uploadCompleted: state => {
      state.queue = [];
      state.progress = 100;
      state.isUploading = false;
      state.uploadComplete = true;
    },
    uploadFailed: state => {
      state.isUploading = false;
      state.uploadComplete = false;
    },
    clearUploadComplete: (state) => {
      state.uploadComplete = false;
    },
  },
});

export const {
  addToQueue,
  startUpload,
  updateProgress,
  uploadCompleted,
  uploadFailed,
  clearUploadComplete,
} = tmsUploaderSlice.actions;
export default tmsUploaderSlice.reducer;
