import React, { useState } from 'react';
import { TMSLoginForm } from '../TMSLoginForm/TMSLoginForm';
import { TMSMapTable } from '../TMSMapTable/TMSMapTable';
import styles from './TMSWrapper.module.css'
import { LOCAL_STORAGE_KEYS } from '../../utils/consts';


export const TMSWrapper = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(() => !!localStorage.getItem(LOCAL_STORAGE_KEYS.TMS_ACCESS_TOKEN));
  
    const handleLoginSuccess = (token) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TMS_ACCESS_TOKEN, token);
      setIsLoggedIn(true);
    };

    return (
      <>
        {!isLoggedIn ? (
          <div className={styles.tMSLoginForm}>
            <TMSLoginForm onSuccess={handleLoginSuccess} />
          </div>
        ) : (
          <TMSMapTable />
        )}
      </>
    );
};
