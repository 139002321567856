import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DefaultLayout from './components/defaultLayout/DefaultLayout';
import { AuthContext } from './context/AuthContext';
import { UploaderContext } from './context/UploaderContext';
import { DataContext } from './context/DataContext';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTranslationGroup } from './store/userSlice';
import { fetchChats } from './store/chatSlice';
import { setShowUnreadIndicator } from './store/uiSlice';
import { incrementUnreadMessages } from './store/unreadMessagesSlice';
import { Button, notification, Space } from 'antd';
import UploadModal from './components/UploadsDropdown/UploadsDropdown'

import { useAuth } from './hooks/auth.hook';
import { useRoutes } from './routes';
import { connectSocket, on, removeAllListeners } from './socket';

import 'antd/dist/reset.css';
import Password2Modal from './components/Password2Modal/Password2Modal';
import { setIsPassword2ModalOpen } from './store/password2Slice';
import { EnterModal } from './pages/mainCallPage/EnterModal';
import { TMSUploader } from './components/TMSUploader/TMSUploader';

const App = () => {
  const [contextUploader, setContextUploader] = useState(false);
  const [contextData, setContextData] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [isEnterModalOpen, setIsEnterModalOpen] = useState(false);
  const [invitedRoomName, setInvitedRoomName] = useState(false);
  const [fullInvitedRoomName, setFullInvitedRoomName] = useState(false);
  const { token, userId, login, logout } = useAuth();
  const isAuth = !!token;
  const { responseAccesses, hasPassword2 } = useSelector((state) => state.userInfo.userState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  connectSocket();
  let routes = useRoutes(isAuth);

  const showPassword2Modal = () => {
    dispatch(setIsPassword2ModalOpen(true));
  };

  useEffect(() => {
    if (hasPassword2 === false) {
      const password2NotificationKey = `open-password2-${Date.now()}`;
      const password2Buttons = (
        <Space>
          <Button type="link" size="small" onClick={() => api.destroy(password2NotificationKey)}>
            Закрыть
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              api.destroy(password2NotificationKey);
              showPassword2Modal();
            }}
          >
            Обновить пароль
          </Button>
        </Space>
      );

      api.info({
        message: 'Обновите пароль',
        description: 'Пожалуйста, обновите пароль',
        placement: 'topRight',
        key: password2NotificationKey,
        duration: 0,
        btn: password2Buttons,
      });
    }
  }, [hasPassword2]);

  useEffect(() => {
    if (userId && responseAccesses) {
      if (responseAccesses['group_translations']) dispatch(fetchTranslationGroup());
      if (responseAccesses['messages']) {
        dispatch(fetchChats(userId));
        on('message_v_0.4_newMessage', (data) => {
          if (data.senderID !== userId.toString()) {
            dispatch(incrementUnreadMessages({ chatId: data.chatID }));
            dispatch(setShowUnreadIndicator(true));
            if (!location.pathname.startsWith('/messages')) {
              const notificationKey = `open${Date.now()}`;
              api.info({
                message: `Чат "${data.chatName}"`,
                description: data.text,
                placement: 'topRight',
                key: notificationKey,
                duration: 3,
                onClick: () => {
                  navigate(`/messages?${data.chatID}`);
                  api.destroy(notificationKey);
                },
              });
            }
          }
        });
      }
      if (responseAccesses['call_page']) {
        on('invitedToCall', ({ roomName, fullRoomName, inviter }) => {
          setInvitedRoomName(roomName);
          setFullInvitedRoomName(fullRoomName);
          const notificationKey = `open${Date.now()}`;
          const btn = (
            <Space>
              <Button type="link" size="small" onClick={() => api.destroy(notificationKey)}>
                Отклонить
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setIsEnterModalOpen(true);
                  api.destroy(notificationKey);
                }}
              >
                Принять
              </Button>
            </Space>
          );
          api.info({
            message: `Приглашение в звонок от ${inviter}`,
            description: '',
            placement: 'topLeft',
            duration: 10,
            btn,
            key: notificationKey,
            duration: 3,
          });
        });
      }
    }
    return () => {
      if (responseAccesses && responseAccesses['messages']) removeAllListeners('message_v_0.4_newMessage');
      if (responseAccesses && responseAccesses['call_page']) removeAllListeners('invitedToCall');
    };
  }, [userId, location.pathname, responseAccesses]);

  return (
    <>
      <AuthContext.Provider
        value={{
          token,
          login,
          logout,
          userId,
          isAuth,
        }}
      >
        {contextHolder}
        {isEnterModalOpen && (
          <EnterModal
            id={invitedRoomName}
            roomName={fullInvitedRoomName}
            isEnterModalOpen={isEnterModalOpen}
            setIsEnterModalOpen={setIsEnterModalOpen}
          />
        )}
        <UploaderContext.Provider value={[contextUploader, setContextUploader]}>
          <DataContext.Provider value={[contextData, setContextData]}>
            <DefaultLayout>{routes}</DefaultLayout>
          </DataContext.Provider>
        </UploaderContext.Provider>
      </AuthContext.Provider>

      <Password2Modal />
      <UploadModal />

      <TMSUploader />

      {/* <Button
        type="primary"
        shape="circle"
        icon={<UploadOutlined />}
        style={{ position: "fixed", bottom: '89%', right: 30, zIndex: 1000 }}
        onClick={() => setUploadModalOpen(true)}
      /> */}
    </>
  );
};

export default App;
