import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosTms } from '../api';

export const downloadTmsMapByFilename = createAsyncThunk(
  'tms/getAllTmsMaps',
  async (filename, __) => {
    const response = await axiosTms.get(
      `/api/maps/download/${filename}`,
      {
        responseType: 'blob',
      }
    );

    const disposition = response.headers['content-disposition'];      
    let downloadFilename = filename;
    if (disposition && disposition.indexOf('filename=') !== -1) {
      const filenameMatch = disposition.match(/filename="?([^"]+)"?/);
      if (filenameMatch && filenameMatch[1]) {
        downloadFilename = filenameMatch[1];
      }
    } else {
      downloadFilename = filename + '.mbtiles';
    }

    const contentType = response.headers['content-type'] || 'application/octet-stream';
    const blob = new Blob([response.data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', downloadFilename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }
);

export const getAllTmsMaps = createAsyncThunk(
  'tms/getAllTmsMaps',
  async ({ offset = 0, limit = 10, type = 'all' }, __) => {
    const response = await axiosTms.get(`/api/maps`, { params: { type, offset, limit } });
    return response.data;
  }
);

export const deleteTmsMapById = createAsyncThunk(
  'tms/deleteTmsMapById',
  async (id, _) => {
    await axiosTms.delete(`/api/maps/${id}`);
  }
);

const tmsSlice = createSlice({
  name: 'tms',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTmsMaps.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTmsMaps.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(getAllTmsMaps.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default tmsSlice.reducer;
